import { SignIn } from "@clerk/clerk-react"
import AuthLayout from "../layouts/auth-layout"

export default function SignInPage() {

  return (
    <AuthLayout>
      <SignIn redirectUrl={"/"} routing="path" path="/sign-in" />
    </AuthLayout>
  )

}