import { UserButton } from "@clerk/clerk-react";
import { cn } from "../lib/utils"
import { BookOpen, Cog, Palette, Rows4Icon, User } from "lucide-react";
import { useState } from "react";
import { useParams } from "react-router";
import { Link, useLocation, useSearchParams } from "react-router-dom";

export function MainNav({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLElement>) {

  const location = useLocation()
  const { pathname } = location;
  const { weddingId } = useParams();

  const routes = [
    {
      href: `/${weddingId}`,
      label: 'Geral',
      active: pathname === `/${weddingId}`,
      icon: <BookOpen className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/guests`,
      label: 'Convidados',
      active: pathname.includes(`/${weddingId}/guests`),
      icon: <User className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/services`,
      label: 'Serviços',
      active: pathname.includes(`/${weddingId}/services`),
      icon: <Rows4Icon className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/colors`,
      label: 'Cores',
      active: pathname.includes(`/${weddingId}/colors`),
      icon: <Palette className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/settings`,
      label: 'Configurações',
      active: pathname === `/${weddingId}/settings`,
      icon: <Cog className="mr-2 h-4 w-4" />
    }
  ];

  return (
    <>
      <nav className={cn("flex items-center space-x-4 lg:space-x-6", className)}>
        {
          routes.map((route) => (
            <Link
              key={route.href}
              to={route.href}
              className={cn("flex items-center text-sm font-medium transition-color hover:text-primary",
                route.active ?
                  "text-pallete-5 dark:text-pallete-3"
                  : "text-muted-foreground")} >
              {route.icon}
              {route.label}
            </Link>
          ))
        }
      </nav>
      <div className="ml-auto items-center space-x-4 mx-6 hidden md:flex ">
        <UserButton afterSignOutUrl='/sign-in' />
      </div>
    </>
  )
}