import * as z from "zod"
import axios from "axios"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { AlertTriangle, Trash } from "lucide-react"
import { useNavigate, useParams } from "react-router";
import { useState } from "react"

import { Input } from "../../../../components/ui/input"
import { Button } from "../../../../components/ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../components/ui/form"
import { Separator } from "../../../../components/ui/separator"
import { Heading } from "../../../../components/ui/heading"
import { AlertModal } from "../../../../components/modals/alert-modal"
import { DatePickerDemo } from "../../../../components/ui/date-picker"
import { Label } from "../../../../components/ui/label"
import { Wedding } from ".."
import { WeddingStatusEnum } from "../../../../config/Config"

const formSchema = z.object({
  firstName: z.string().min(1),
  secondName: z.string().min(1),
  url: z.string().min(1),
  weddingDate: z.coerce.date().refine((data) => data > new Date(), { message: "Data deverá ser algures no futuro." })
});

type SettingsFormValues = z.infer<typeof formSchema>

interface SettingsFormProps {
  initialData: Wedding | undefined;
};

export const SettingsForm: React.FC<SettingsFormProps> = ({
  initialData
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const form = useForm<SettingsFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: initialData
  });

  const refreshPage = () => {
    window.location.reload();
  };

  const onSubmit = async (data: SettingsFormValues) => {
    // try {
    //   setLoading(true);
    //   await axios.patch(`/api/weddings/${params.weddingId}`, data);
    //   refreshPage();
    //   toast.success('Casamento atualizado.');
    // } catch (error: any) {
    //   toast.error('Something went wrong.');
    // } finally {
    //   setLoading(false);
    // }
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`/api/weddings/${params.weddingId}`);
      refreshPage();
      navigate('/');
      toast.success('Casamento eliminado.');
    } catch (error: any) {
      toast.error('Make sure you removed all products and categories first.');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  }

  return (
    <>
      <AlertModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        loading={loading}
      />
      <div className="flex items-center justify-between">
        <Heading title="Configurações Gerais" description="Escolha os nomes dos noivos do casamento e a data em que ele será realizado, assim como o link público." />
        <Button
          disabled={loading}
          variant="destructive"
          size="sm"
          onClick={() => setOpen(true)}
        >
          <Trash className="h-4 w-4" />
        </Button>
      </div>
      <Separator />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 w-full">
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Primeiro nome</FormLabel>
                  <FormControl>
                    <Input disabled={loading || initialData?.weddingStatusId != WeddingStatusEnum.created} placeholder="Primeiro nome" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Segundo nome</FormLabel>
                  <FormControl>
                    <Input disabled={loading || initialData?.weddingStatusId != WeddingStatusEnum.created} placeholder="Segundo nome" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
            <FormField
              control={form.control}
              name="weddingDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Data do casamento</FormLabel>
                  <FormDescription>Dia no qual vai ocorrer o casamento</FormDescription>
                  <FormControl>
                    <DatePickerDemo
                      disabled={initialData?.weddingStatusId != WeddingStatusEnum.created}
                      date={field.value}
                      setDate={field.onChange}
                      fromDate={new Date()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-8 grid-cols-1 lg:grid-cols-2">
            <FormField
              control={form.control}
              name="url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Link público</FormLabel>
                  <FormDescription>Escolha o link público que permitirá o acesso à página do casamento.</FormDescription>
                  <FormControl >
                    <div className="flex flex-row items-center">
                      <Label>https://digitalwedding.pt/</Label>
                      <Input className="ml-3" disabled={loading || initialData?.weddingStatusId != WeddingStatusEnum.created} placeholder="url nome" {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

          </div>
          {initialData?.weddingStatusId != WeddingStatusEnum.created &&
            <div className="rounded-md bg-red-300 w-max p-3">
              <p className="text-sm flex text-accent-foreground">
                <AlertTriangle className="mr-2" size={20} />
                Devido ao casamento estar ativo, estas configurações não podem ser alteradas.
              </p>
            </div>
          }

          <Button
            disabled={loading || initialData?.weddingStatusId != WeddingStatusEnum.created}
            className="ml-auto" type="submit">
            Guardar
          </Button>
        </form>
      </Form>
    </>
  );
};