import { cn } from "../lib/utils"
import { AlignJustify, BookOpen, Cog, Palette, Rows4Icon, User } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "./ui/command"

import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Button } from "./ui/button";
import { useOrigin } from "../hooks/use-origin";

export function MainNavMobile({
  className,
  ...props
}: React.HtmlHTMLAttributes<HTMLElement>) {

  const location = useLocation()
  const navigate = useNavigate()
  const { weddingId } = useParams();
  const { pathname } = location;

  const [open, setOpen] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const origin = useOrigin();

  const onWeddingSelect = (href: string) => {
    setOpen(false);
    navigate(`${href}`)
  }

  const routes = [
    {
      href: `/${weddingId}`,
      label: 'Geral',
      active: pathname === `/${weddingId}`,
      icon: <BookOpen className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/guests`,
      label: 'Convidados',
      active: pathname.includes(`/${weddingId}/guests`),
      icon: <User className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/services`,
      label: 'Serviços',
      active: pathname.includes(`/${weddingId}/services`),
      icon: <Rows4Icon className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/colors`,
      label: 'Cores',
      active: pathname.includes(`/${weddingId}/colors`),
      icon: <Palette className="mr-2 h-4 w-4" />
    },
    {
      href: `/${weddingId}/settings`,
      label: 'Configurações',
      active: pathname === `/${weddingId}/settings`,
      icon: <Cog className="mr-2 h-4 w-4" />
    }
  ];

  return (
    <>
      <Popover
        open={open}
        onOpenChange={setOpen}
      >
        <PopoverTrigger asChild >
          <Button
            variant={"outline"}
            size={"sm"}
            aria-expanded={open}
            aria-label="Escolha um casamento"
            // className={cn(" justify-between", className)}
            className={cn("ml-auto flex items-center space-x-4 mr-0", className)}
          >
            <AlignJustify className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" >
          <Command>
            <CommandList>
              <CommandInput placeholder="Procurar..." />
              <CommandEmpty >Nenhuma funcionalidade encontrada</CommandEmpty>
              <CommandGroup heading="Casamentos">
                {
                  routes.map(wedding => (
                    <CommandItem
                      key={wedding.href}
                      onSelect={() => onWeddingSelect(wedding.href)}
                      className="text-sm"
                    >
                      {wedding.icon}
                      {wedding.label}
                    </CommandItem>
                  ))
                }
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  )
}