import { ColumnDef } from "@tanstack/react-table"
import CellAction from "./cell-action"
import { CheckSquare, XSquare } from "lucide-react"

export type GuestColumn = {
  id: string
  firstName: string
  lastName: string
  age: string
  attendance: boolean
  createdAt: string
}

const getAgeString = (ageId: string) => {
  switch (ageId) {
    case '0':
      return "Criança";
    case '1':
      return "Criança < 3 anos";
    case '2':
      return "Adulto";
  }
}

export const columns: ColumnDef<GuestColumn>[] = [
  {
    accessorKey: "firstName",
    header: "Primeiro Nome",
  },
  {
    accessorKey: "lastName",
    header: "Último Nome"

  },
  {
    accessorKey: "age",
    header: "Idade",
    cell: ({ row }) => getAgeString(row.original.age)
  },
  {
    accessorKey: "attendance",
    header: "Resposta",
    cell: ({ row }) => row.original.attendance ? <CheckSquare color="green" /> : <XSquare color="red" />
  },
  {
    accessorKey: "createdAt",
    header: "Data",
  },
  {
    id: "actions",
    cell: ({ row }) => <CellAction data={row.original} />
  }
]
