'use client'

import { useState, useEffect } from "react"

import { WeddingModal } from "../components/modals/store-modal";

export const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <WeddingModal />
    </>
  )
}