import { useState, useEffect } from "react";
import { Button } from "../ui/button";
import { Modal } from "../ui/modal";

interface AlertModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  loading,
}) => {
  const [isMounted, setIsMonted] = useState(false);

  useEffect(() => {
    setIsMonted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Modal
      title="Tem a certeza?"
      description="Esta ação não pode ser desfeita."
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="pt-6 space-x-2 flex item-center justify-end w-full">
        <Button disabled={loading} variant={"outline"} onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={loading} variant={"destructive"} onClick={onConfirm}>
          Continuar
        </Button>
      </div>
    </Modal>
  );
};
