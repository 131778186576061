import { Link } from "react-router-dom";
import version from '../lib/version';

//This component is not add inside the UI folder because it's not a reusable component
const Footerbar = () => {

  const routes = [
    {
      href: ``,
      label: 'Contactos'
    },
    {
      href: ``,
      label: 'Termos e condições'
    },
    {
      href: ``,
      label: 'Políticas de privacidade'
    }
  ];

  return (
    <footer className="p-4 w-full bg-white border-t flex flex-col lg:flex-row items-start lg:items-center ">
      <div className="inline-flex items-center rounded-md text-xs text-muted-foreground py-2 px-4 lg:flex-1">
        © {new Date().getFullYear()} Digital Wedding. All rights reserved. v.{version}
      </div>
      {
        routes.map((element) => (
          <Link
            key={element.label}
            to={element.href}
            className="inline-flex items-center justify-center rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 underline-offset-4 hover:underline h-10 px-4 py-2 text-muted-foreground text-xs" >
            {element.label}
          </Link>
        ))
      }
    </footer>
  );
}

export default Footerbar;