import { useEffect } from "react";
import { MainNav } from "./main-nav";
import StoreSwitcher, { WeddingSwitcherProps } from "./store-switcher";
import { useAuth } from "@clerk/clerk-react";
import { useNavigate } from "react-router";
import { MainNavMobile } from "./main-nav-mobile";

const Navbar = ({
  items = []
}: WeddingSwitcherProps) => {

  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoaded && !userId) {
      navigate('/')
      return;
    }
  }, [userId])

  return (
    <div className="border-b">
      <div className="flex h-16 items-center px-4">
        <StoreSwitcher items={items} />
        <MainNav className="mx-6 hidden md:flex" />
        <MainNavMobile className="flex md:hidden" />
      </div>
    </div>
  );
}

export default Navbar;