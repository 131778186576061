import { SignUp } from "@clerk/clerk-react"
import AuthLayout from "../layouts/auth-layout"

export default function SignUpPage() {

  return (
    <AuthLayout>
      <SignUp redirectUrl={"/"} routing="path" path="/sign-up" />
    </AuthLayout>
  )

}