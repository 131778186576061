import loadingImg from "../../assets/loading.gif";
import { useLoading } from "../../hooks/use-loading";


const Loading = () => {
  const loading = useLoading();
  return (
    <>
      {
        loading.isOpen &&
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#c1c1c1] bg-opacity-40 text-white text-2xl">
          <img className="h-10" src={loadingImg} />
        </div>
      }
    </>
  );
}

export default Loading;
