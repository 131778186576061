import { Response } from "../models/Response";
import { GetWeddingGuestsResponse } from "../models/weddings/GetWeddingGuestsResponse";
import { GetWeddingDetailsResponse } from "../models/weddings/GetWeddingDetailsResponse";
import { Config } from "../config/Config";
 
export interface IWeddingService {
  weddingBelongsToUser(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<boolean>>;
  getWeddingGuests(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<GetWeddingGuestsResponse>>;
  getWeddingDetails(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<GetWeddingDetailsResponse>>;
}

export class WeddingService implements IWeddingService {
  async weddingBelongsToUser(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<boolean>> {
    const resp = await fetch(
      Config.apiUrl +
      "users/" + contract.userId + "/weddings",
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (resp.ok) {
      const result = await resp.json();
      const list = result.list;
      const isIdIncluded: boolean = list.some(
        (item: { id: string }) => item.id === contract.weddingId
      );
      return { kind: "success", value: isIdIncluded };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        // if (error.code === "200003") {
        //   return { kind: "success", value: error };
        // }

        return {
          kind: "error",
          code: error.code ?? 0,
          message: error.message ?? "",
        };
      }
    }

    return {
      kind: "unexpected_error",
    };
  }

  async getWeddingGuests(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<GetWeddingGuestsResponse>> {
    const resp = await fetch(
      Config.apiUrl +
      "weddings/" + contract.weddingId + "/guests?page_size=999999",
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (resp.ok) {
      var result: GetWeddingGuestsResponse = await resp.json();

      return { kind: "success", value: result };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        // if (error.code === "200003") {
        //   return { kind: "success", value: error };
        // }

        return {
          kind: "error",
          code: error.code ?? 0,
          message: error.message ?? "",
        };
      }
    }

    return {
      kind: "unexpected_error",
    };
  }
  async getWeddingDetails(contract: {
    userId: string;
    weddingId: string;
  }): Promise<Response<GetWeddingDetailsResponse>> {
    const resp = await fetch(
      Config.apiUrl +
      "weddings/" + contract.weddingId,
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (resp.ok) {
      var result: GetWeddingDetailsResponse = await resp.json();

      return { kind: "success", value: result };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        // if (error.code === "200003") {
        //   return { kind: "success", value: error };
        // }

        return {
          kind: "error",
          code: error.code ?? 0,
          message: error.message ?? "",
        };
      }
    }

    return {
      kind: "unexpected_error",
    };
  }
}

export const weddingService = new WeddingService();
