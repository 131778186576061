import { useEffect } from 'react';

import { useWeddingModal } from "../hooks/use-wedding-modal";

export default function IndexPage() {
  const onOpen = useWeddingModal((state) => state.onOpen);
  const isOpen = useWeddingModal((state) => state.isOpen);

  useEffect(() => {
    if (!isOpen) {
      onOpen();
    }
  }, [isOpen, onOpen]);

  return null;
} 