import { useNavigate, useParams } from "react-router";
import logo from '../../assets/login-bg.jpg'
import { Button } from "../../components/ui/button"
import { Label } from "../../components/ui/label"
import "../../styles/NotFoundPage.css"
import { SignInButton, SignedIn, SignedOut, UserButton, useAuth } from "@clerk/clerk-react";
import { Heading } from "../../components/ui/heading";
import { Separator } from "../../components/ui/separator";
import { useCallback, useEffect } from "react";
import { useLoading } from "../../hooks/use-loading";
import { Card, CardContent, CardHeader, CardTitle } from "../../components/ui/card";
import { CreditCard, Package, User } from "lucide-react";

export default function DashboardPage() {
  const loading = useLoading();
  const navigate = useNavigate();
  const { userId, isLoaded } = useAuth();
  const { weddingId } = useParams();

  const initialize = useCallback(async (userId: string) => {

    loading.onClose();
  }, [])

  useEffect(() => {
    loading.onOpen();

    if (isLoaded) {
      if (!userId || !weddingId) {
        navigate('/')
        return;
      }
      initialize(userId);
    }

  }, [initialize, userId])

  return (
    <div className="flex-col ">
      <div className="flex-1 space-y-4 p-8 pt-6">
        <Heading title="Dashboard" description="Visão geral do casamento" />
        <Separator />
        <div className="grid gap-4 grid-cols-3">
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Total de Convidados</CardTitle>
              <User className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="flex flex-row text-sm items-center">
                <div className="text-2xl font-bold" title="Convidados aceites">{1}</div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Aceites</CardTitle>
              <CreditCard className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {1}
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Rejeitados</CardTitle>
              <Package className="h-4 w-4 text-muted-foreground" />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">
                {10 - 1}
              </div>
            </CardContent>
          </Card>
        </div>
        <Card className="col-span-4">
          <CardHeader>
            <CardTitle>
              Overview
            </CardTitle>
          </CardHeader>
          <CardContent className="pl-2">
            {/* <Overview data{[]}/> */}
          </CardContent>
        </Card>
      </div>
    </div>
  )
}