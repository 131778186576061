import { ColorsClient } from "./components/client";
import { ColorColumn } from "./components/columns";

import { timeAgo } from "../../../lib/utils";
import { useNavigate, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { weddingService } from "../../../services/WeddingService";
import { useLoading } from "../../../hooks/use-loading";

const ColorsPage = () => {
  const { userId, isLoaded } = useAuth();
  const { weddingId } = useParams();
  const navigate = useNavigate();
  const loading = useLoading();

  const [isInitializing, setIsInitializing] = useState(true);
  const [formattedSizes, setFormattedSizes] = useState<ColorColumn[]>([])

  const initialize = useCallback(async (userId: string) => {
    if (!weddingId || !userId) {
      loading.onClose();
      return;
    }

    // const getWeddingColorsResult = await weddingService.getWeddingColors({ userId, weddingId });
    // if (getWeddingColorsResult.kind !== "success") {
    //   loading.onClose();
    //   navigate('/')
    //   return;
    // }

    // const aux: ColorColumn[] = getWeddingColorsResult.value.list.map((item) => ({
    //   id: item.id,
    //   name: item.name,
    //   value: item.value,
    //   updatedAt: timeAgo(item.updatedAt) as string
    // }))
    // setFormattedSizes(aux);
    // setIsInitializing(false);
    loading.onClose();

  }, [])

  useEffect(() => {
    loading.onOpen();
    if (isLoaded) {
      if (!userId || !weddingId) {
        navigate('/sign-in')
        return;
      }
      initialize(userId);
    }
  }, [initialize, userId])

  return (
    <div className="flex-col">
      <div className="flex-1 space-y-4 p-8 pt-6">
        {!isInitializing && <ColorsClient data={formattedSizes} />}
      </div>
    </div>
  )

}

export default ColorsPage;