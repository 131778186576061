import { SignIn, useAuth } from "@clerk/clerk-react"
import AuthLayout from "../layouts/auth-layout"
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";

export default function SignOutPage() {
  const navigate = useNavigate();
  const { userId, isLoaded } = useAuth();
  const initialize = useCallback(async (userId: string) => {

    window.Clerk?.signOut();
  }, [])

  useEffect(() => {

    if (isLoaded) {
      if (!userId) {
        navigate('/')
        return;
      }
      initialize(userId);
    }

  }, [initialize, userId])

  return (
    <AuthLayout>
      <SignIn redirectUrl={"/"} routing="path" path="/sign-in" />
    </AuthLayout>
  )

}