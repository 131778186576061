import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import './styles/globals.css';
import { useNavigate, BrowserRouter, Routes, Route } from 'react-router-dom'

// Import the layouts
import RootLayout from './layouts/root-layout'
import DashboardLayout from './layouts/dashboard-layout'

// Import the components
import IndexPage from './routes'
import NotFoundPage from './routes/not-found-page'
import DashboardPage from './routes/dashboard'
import GuestsPage from './routes/dashboard/guests';
import SettingsPage from './routes/dashboard/settings';
import ColorsPage from './routes/dashboard/colors';
import ServicePage from './routes/dashboard/services';
import ServiceAlterAddPage from './routes/dashboard/services/[serviceId]';


import { ClerkProvider } from '@clerk/clerk-react'
import SignInPage from './routes/sign-in';
import SignUpPage from './routes/sign-up';
import { ToasterProvider } from './providers/toast-provider';
import { ModalProvider } from './providers/modal-provider';
import { LoadingProvider } from './providers/loading-provider';
import SignOutPage from './routes/sign-out';

const PUBLISHABLE_KEY = process.env.REACT_APP_VITE_CLERK_PUBLISHABLE_KEY ?? "";

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const root = ReactDOM.createRoot(document.getElementById('root')!);

const ClerkWithRoutes = () => {
  const navigate = useNavigate();

  return (
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      navigate={(to) => navigate(to)}
    >
      <Routes>
        <Route
          path="/"
          element={
            <RootLayout><IndexPage /></RootLayout>
          }
        />
        <Route
          path="/:weddingId"
          element={
            <DashboardLayout><DashboardPage /></DashboardLayout>
          }
        />
        <Route
          path="/:weddingId/guests"
          element={
            <DashboardLayout><GuestsPage /></DashboardLayout>
          }
        />
        <Route
          path="/:weddingId/settings"
          element={
            <DashboardLayout><SettingsPage /></DashboardLayout>
          }
        />
        <Route
          path="/:weddingId/colors"
          element={
            <DashboardLayout><ColorsPage /></DashboardLayout>
          }
        />
        <Route
          path="/:weddingId/services"
          element={
            <DashboardLayout><ServicePage /></DashboardLayout>
          }
        />
        <Route
          path="/:weddingId/services/:serviceId"
          element={
            <DashboardLayout><ServiceAlterAddPage /></DashboardLayout>
          }
        />
        <Route
          path='/sign-in/*'
          element={<SignInPage />}
        />
        <Route
          path='/sign-out/*'
          element={<SignOutPage />}
        />
        <Route
          path='/sign-up/*'
          element={<SignUpPage />}
        />
      </Routes>
    </ClerkProvider>
  )
}

root.render(
  <React.StrictMode>
    <ToasterProvider />
    <ModalProvider />
    <LoadingProvider />
    <BrowserRouter>
      <ClerkWithRoutes />
    </BrowserRouter>
  </React.StrictMode>,
)