import bg from '../assets/login-bg.jpg'

export default function AuthLayout({
  children
}: {
  children: React.ReactNode
}) {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className="bg-cover bg-no-repeat flex absolute h-screen w-screen bg-center -z-50"
      >
      </div>
      <div className="flex items-center justify-center h-screen">
        {children}
      </div>
    </>
  )
}