import { GuestsClient } from "./components/client";
import { GuestColumn } from "./components/columns";

import { timeAgo } from "../../../lib/utils";
import { useNavigate, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
import { weddingService } from "../../../services/WeddingService";
import { useLoading } from "../../../hooks/use-loading";

const GuestsPage = () => {
  const { userId, isLoaded } = useAuth();
  const { weddingId } = useParams();
  const navigate = useNavigate();
  const loading = useLoading();

  const [isInitializing, setIsInitializing] = useState(true);
  const [formattedGuests, setFormattedSizes] = useState<GuestColumn[]>([])

  const initialize = useCallback(async (userId: string) => {
    if (!weddingId || !userId) {
      loading.onClose();
      return;
    }

    const getWeddingGuestsResult = await weddingService.getWeddingGuests({ userId, weddingId });
    if (getWeddingGuestsResult.kind !== "success") {
      loading.onClose();
      navigate('/')
      return;
    }

    const aux: GuestColumn[] = getWeddingGuestsResult.value.list.map((item) => ({
      id: item.id,
      firstName: item.first_name,
      lastName: item.last_name,
      age: item.age,
      attendance: item.attendance,
      createdAt: timeAgo(item.created_at) as string
    }))
    setFormattedSizes(aux);
    setIsInitializing(false);
    loading.onClose();
  }, [])

  useEffect(() => {
    loading.onOpen();
    if (isLoaded) {
      if (!userId || !weddingId) {
        navigate('/sign-in')
        return;
      }
      initialize(userId);
    }
  }, [userId])

  return (
    <div className="flex-col">
      <div className="flex-1 space-y-4 p-8 pt-6">
        <GuestsClient data={formattedGuests} />
      </div>
    </div>
  )

}

export default GuestsPage;