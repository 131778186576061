import { useAuth } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router";
import { SettingsForm } from "./components/settings-form";
import { useCallback, useEffect, useState } from "react";
import { weddingService } from "../../../services/WeddingService";
import { useLoading } from "../../../hooks/use-loading";

export const revalidate = 0;

export type Wedding = {
  id: string;
  weddingDate: Date;
  weddingStatusId: number;
  firstName: string;
  secondName: string;
  url: string;
  email: string;
}

const SettingsPage = () => {
  const { userId, isLoaded } = useAuth();
  const navigate = useNavigate();
  const { weddingId } = useParams();
  const loading = useLoading();

  const [wedding, setWedding] = useState<Wedding>()
  const [isInitializing, setIsInitializing] = useState(true);

  const initialize = useCallback(async (userId: string) => {
    if (!weddingId || !userId) {
      loading.onClose();
      return;
    }

    const getWeddingGuestsResult = await weddingService.getWeddingDetails({ userId, weddingId });
    if (getWeddingGuestsResult.kind !== "success") {
      navigate('/')
      loading.onClose();
      return;
    }

    const getWeddingDetails: Wedding = {
      id: getWeddingGuestsResult.value.id,
      firstName: getWeddingGuestsResult.value.first_name,
      secondName: getWeddingGuestsResult.value.second_name,
      weddingStatusId: getWeddingGuestsResult.value.status_id,
      url: getWeddingGuestsResult.value.url,
      weddingDate: getWeddingGuestsResult.value.wedding_date,
      email: getWeddingGuestsResult.value.email
    }

    setWedding(getWeddingDetails)
    setIsInitializing(false);
    loading.onClose();
  }, [])

  useEffect(() => {
    loading.onOpen();
    if (isLoaded) {
      if (!userId || !weddingId) {
        navigate('/')
        return;
      }
      initialize(userId);
    }
  }, [initialize, userId])

  return (
    <div className="flex-col">
      <div className="flex-1 space-y-4 p-8 pt-6">
        {!isInitializing && <SettingsForm initialData={wedding} />}
      </div>
    </div>
  );
}

export default SettingsPage;