import { useCallback, useEffect, useState } from 'react'
import { useAuth } from "@clerk/clerk-react"
import { useNavigate } from "react-router-dom"
import { useParams } from 'react-router-dom'

import Loading from '../components/ui/loading'
import { weddingService } from '../services/WeddingService'
import Navbar from '../components/navbar'
import Footerbar from '../components/footerbar'




import loadingImg from "../assets/logo.png";
import { useLoading } from '../hooks/use-loading'
import { userService } from '../services/UserService'

type WeddingProps = {
  firstName: string;
  secondName: string;
  id: string;
}[];

export default function DashboardLayout({
  children
}: {
  children: React.ReactNode
}) {
  const loading = useLoading();
  const navigate = useNavigate();
  const [isInitializing, setIsInitializing] = useState(true);
  const [weddings, setWeddings] = useState<{
    firstName: string;
    secondName: string;
    id: string;
  }[]>([])

  const { userId, isLoaded } = useAuth();

  if (isLoaded && !userId) {
    navigate('/sign-in')
  }

  const { weddingId } = useParams();

  if (!weddingId) {
    navigate('/')
  }

  const initialize = useCallback(async (userId: string) => {
    if (!weddingId) {
      return;
    }

    const weddingResult = await weddingService.weddingBelongsToUser({ userId, weddingId });
    if (weddingResult.kind === "error" || weddingResult.kind == "success" && !weddingResult.value) {
      navigate('/')
      return;
    }

    const getUserWeddingsResult = await userService.getUserWeddings(userId);
    if (getUserWeddingsResult.kind !== "success") {
      navigate('/')
      return;
    }

    const userWeddings: {
      firstName: string;
      secondName: string;
      id: string;
    }[] = getUserWeddingsResult.value.list.map((item) => ({
      id: item.id,
      firstName: item.first_name,
      secondName: item.second_name,
    }))

    setWeddings(userWeddings)
    setIsInitializing(false);
  }, [])

  useEffect(() => {
    if (isLoaded) {
      if (!userId) {
        navigate('/sign-in')
        return;
      }
      initialize(userId);

    }
  }, [initialize, userId])

  return (
    <>
      <div className='flex flex-col h-dvh'>
        <div className="fixed inset-0 z-[-50] flex items-center justify-center bg-white opacity-[0.04] text-white text-2xl">
          <img className="h-80 object-contain" src={loadingImg} />
        </div>
        <div className='flex-1'>
          <Navbar items={weddings} />
          {children}
        </div>
        <Footerbar />
      </div>
    </>
  );
}