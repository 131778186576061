import { Config } from "../config/Config";
import { Response } from "../models/Response";
import { GetUserWeddingsResponse } from "../models/users/GetUserWeddingsResponse";

export interface IUserService {
  getUserWeddings(userId: string): Promise<Response<GetUserWeddingsResponse>>;
}

export class UserService implements IUserService {
  async getUserWeddings(
    userId: string
  ): Promise<Response<GetUserWeddingsResponse>> {
    const resp = await fetch(
      Config.apiUrl +
      "users/" + userId + "/weddings",
      {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );

    if (resp.ok) {
      var result: GetUserWeddingsResponse = await resp.json();

      return { kind: "success", value: result };
    } else if (resp.status === 400) {
      const error = await resp.json();

      if (error) {
        // if (error.code === "200003") {
        //   return { kind: "success", value: error };
        // }

        return {
          kind: "error",
          code: error.code ?? 0,
          message: error.message ?? "",
        };
      }
    }

    return {
      kind: "unexpected_error",
    };
  }
}

export const userService = new UserService();
