import axios from "axios";
import toast from "react-hot-toast";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "../../../../components/ui/dropdown-menu";
import { useNavigate, useParams } from "react-router";

import { GuestColumn } from "./columns";
import { Button } from "../../../../components/ui/button";
import { Copy, Edit, MoreHorizontal, Trash } from "lucide-react";
import { useState } from "react";
import { AlertModal } from "../../../../components/modals/alert-modal";

interface CellActionColumns {
  data: GuestColumn
}

const CellAction: React.FC<CellActionColumns> = ({
  data
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onCopy = (id: string) => {
    navigator.clipboard.writeText(id);
    toast.success("Id do convidado copiado para o clipboard")
  }

  const refreshPage = () => {
    window.location.reload();
  };

  const onDelete = async () => {
    // try {
    //   setLoading(true);
    //   await axios.delete(`/api/${params.weddingId}/guests/${data.id}`);
    //   refreshPage();
    //   toast.success("Convidado eliminado!");
    // } catch (error: any) {
    //   toast.error('Make sure you removed all products first using this guest first.');
    // } finally {
    //   setLoading(false);
    //   setOpen(false);
    // }
  }

  return (
    <>
      <AlertModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        loading={loading}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Abrir menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>
            Ações
          </DropdownMenuLabel>
          <DropdownMenuItem onClick={() => onCopy(data.id)}>
            <Copy className="mr-2 h-4 w-4" />
            Copiar Id
          </DropdownMenuItem>
          <DropdownMenuItem
          // onClick={() => navigate(`/${params.weddingId}/guests/${data.id}`)}
          >
            <Edit className="mr-2 h-4 w-4" />
            Editar
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setOpen(true)}>
            <Trash className="mr-2 h-4 w-4" />
            Eliminar
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </  >

  );
}

export default CellAction;