import { Plus } from "lucide-react";
import { useNavigate, useParams } from "react-router";

import { Button } from "../../../../components/ui/button";
import { DataTable } from "../../../../components/ui/data-table";
import { Heading } from "../../../../components/ui/heading";
import { Separator } from "../../../../components/ui/separator";

import { columns, ColorColumn } from "./columns";

interface GuestsClientProps {
  data: ColorColumn[];
}

export const ColorsClient: React.FC<GuestsClientProps> = ({
  data
}) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center justify-between">
        <Heading title={`Cores (${data.length})`}
          description="Faça gestão das cores do seu casamento" />
        <Button onClick={() => navigate(`/${params.weddingId}/colors/new`)}>
          <Plus className="mr-2 h-4 w-4" /> Adicionar
        </Button>
      </div>
      <Separator />
      <DataTable searchKey="firstName" columns={columns} data={data} placeholder="Procurar convidados..." />
    </>
  );
};