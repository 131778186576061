import { useAuth } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router";
import { useCallback, useEffect, useState } from "react";

import { useLoading } from "../../../../hooks/use-loading";
import { Heading } from "../../../../components/ui/heading";
import { Button } from "../../../../components/ui/button";
import { Separator } from "../../../../components/ui/separator";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../../../components/ui/card";

import bg from '../../../../assets/login-bg.jpg'
import qr from '../../../../assets/qr-code.jpeg'
import photoFeed from '../../../../assets/photo-feed.webp'

const ServicePage = () => {
  const { userId, isLoaded } = useAuth();
  const { weddingId } = useParams();
  const navigate = useNavigate();
  const loading = useLoading();

  const [isInitializing, setIsInitializing] = useState(true);

  const initialize = useCallback(async (userId: string) => {
    if (!weddingId || !userId) {
      loading.onClose();
      return;
    }

    // const getWeddingColorsResult = await weddingService.getWeddingColors({ userId, weddingId });
    // if (getWeddingColorsResult.kind !== "success") {
    //   loading.onClose();
    //   navigate('/')
    //   return;
    // }

    // const aux: ServiceColumn[] = getWeddingColorsResult.value.list.map((item) => ({
    //   id: item.id,
    //   name: item.name,
    //   value: item.value,
    //   updatedAt: timeAgo(item.updatedAt) as string
    // }))
    // setFormattedSizes(aux);
    // setIsInitializing(false);
    loading.onClose();

  }, [])

  useEffect(() => {
    loading.onOpen();
    if (isLoaded) {
      if (!userId || !weddingId) {
        navigate('/sign-in')
        return;
      }
      initialize(userId);
    }
  }, [initialize, userId])

  return (
    <div className="flex-col">
      <div className="flex-1 space-y-4 pt-8 p-6">
        <div className="flex items-center justify-between">
          <Heading title={`Os nossos serviços`}
            description="Escolha um dos nossos serviços para o seu casamento" />
        </div>
        <Separator />
        <div className="grid gap-4 grid-cols-1 md:grid-cols-3">
          <Card className="shadow-lg">
            <CardContent className="p-0">
              <div className="text-2xl font-bold">
                <img className="rounded-t-lg" src={bg} />
              </div>
            </CardContent>
            <CardHeader className="flex flex-column items-start justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Wedding Hub</CardTitle>
              <CardDescription>Este serviço fornece uma plataforma central para todas as informações do seu grande dia. Os convidados podem encontrar detalhes essenciais, responder aos convites diretamente no website e personalizar as suas preferências para uma experiência única.</CardDescription>
              <Button variant={'link'} className="mt-6 px-0">Saber mais</Button>
            </CardHeader>
          </Card>
          <Card className="shadow-lg">
            <CardContent className="p-0">
              <div className="text-2xl font-bold">
                <img className="rounded-t-lg" src={qr} />
              </div>
            </CardContent>
            <CardHeader className="flex flex-column items-start justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Seating Simplicity</CardTitle>
              <CardDescription>O serviço que simplifica a organização dos lugares à mesa, permitindo aos noivos a fácil gestão dos lugares dos convidados na hora da refeição. Uma ferramenta intuitiva e eficiente para garantir que cada convidado se senta no lugar certo.</CardDescription>
              <Button variant={'link'} className="mt-6 px-0">Saber mais</Button>
            </CardHeader>
          </Card>
          <Card className="shadow-lg">
            <CardContent className="p-0 relative">
              <div className="text-2xl font-bold relative">
                <img className="rounded-t-lg" src={photoFeed} alt="Background" />
                <div className="flex justify-center items-center absolute z-[50] inset-0 bg-black text-white opacity-50 rounded-t-lg">
                  <p>Brevemente disponível</p>
                </div> {/* Overlay */}
              </div>
            </CardContent>
            <CardHeader className="flex flex-column items-start justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">Unforgettable Snaps</CardTitle>
              <CardDescription>Este é o serviço de fotografia interativa que transforma momentos em recordações inesquecíveis. Com um simples scan do QR code, os convidados podem capturar e partilhar instantaneamente momentos especiais do casamento num feed privado, criando uma narrativa visual única do grande dia.</CardDescription>
            </CardHeader>
          </Card>

        </div>
      </div>
    </div>
  );

}

export default ServicePage;