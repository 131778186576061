import { useState } from "react"
import { Check, ChevronsUpDown, Gem, PlusCircle } from "lucide-react"

import { cn } from "../lib/utils"
import { useWeddingModal } from "../hooks/use-wedding-modal"
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover"
import { Button } from "./ui/button"
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "./ui/command"
import { useNavigate, useParams } from "react-router"

type PopoverTriggerProps = React.ComponentPropsWithRef<typeof PopoverTrigger>

export interface WeddingSwitcherProps extends PopoverTriggerProps {
  items: { firstName: string, secondName: string, id: string }[]
}

export default function WeddingSwitcher({
  className,
  items = []
}: WeddingSwitcherProps) {
  const weddingModal = useWeddingModal();
  const params = useParams();
  const navigate = useNavigate()

  const formattedItems = items.map(item => ({
    label: `${item.firstName} & ${item.secondName}`,
    value: item.id
  }))
  const currentWedding = formattedItems.find(item => item.value === params.weddingId)

  const [open, setOpen] = useState(false);

  const onWeddingSelect = (wedding: { value: string, label: string }) => {
    setOpen(false);
    navigate(`/${wedding.value}`)
  }

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
    >
      <PopoverTrigger asChild >
        <Button
          variant={"outline"}
          size={"sm"}
          role="combobox"
          aria-expanded={open}
          aria-label="Escolha um casamento"
          className={cn("w-[200px] justify-between", className)}
        >
          <Gem className="mr-2 h-4 w-4" />
          {currentWedding?.label}
          <ChevronsUpDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" >
        <Command>
          <CommandList>
            <CommandInput placeholder="Procurar casamento..." />
            <CommandEmpty >Não foi encontrado casamento</CommandEmpty>
            <CommandGroup heading="Casamentos">
              {
                formattedItems.map(wedding => (
                  <CommandItem
                    key={wedding.value}
                    onSelect={() => onWeddingSelect(wedding)}
                    className="text-sm"
                  >
                    <Gem className="mr-2 h-4 w-4" />
                    {wedding.label}
                    <Check
                      className={cn(
                        "ml-auto h-4 w-4",
                        currentWedding?.value === wedding.value
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                  </CommandItem>
                ))
              }
            </CommandGroup>
          </CommandList>
          <CommandSeparator />
          <CommandList>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  setOpen(false)
                  weddingModal.onOpen()
                }}
              >
                <PlusCircle className="mr-2 h-5 w-5" />
                Criar casamento
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}